/*
 *
 */
import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { normalize } from 'polished';
import { IntlProvider, useIntl } from 'react-intl';
import translations, { DEFAULT_LANG } from 'i18n';
import { getCurrentLangKey } from 'ptz-i18n';

//
import AppBar from 'components/app-bar';
import Footer from 'components/footer';
import Helmet from 'react-helmet';

if (!Intl.PluralRules) {
	require('@formatjs/intl-pluralrules/polyfill');
	require('@formatjs/intl-pluralrules/dist/locale-data/nl');
	require('@formatjs/intl-pluralrules/dist/locale-data/fr');
}

if (!Intl.RelativeTimeFormat) {
	require('@formatjs/intl-relativetimeformat/polyfill');
	require('@formatjs/intl-relativetimeformat/dist/locale-data/nl');
	require('@formatjs/intl-relativetimeformat/dist/locale-data/fr');
}

/*
 *
 */
const PageContent = styled.div`
	flex:1 0 auto;
	overflow:hidden;
`;

const PageFooter = styled.div`
	flex:0 0 auto;
`;

const GlobalStyle = createGlobalStyle`

	${normalize()}

	html, body {
		height:100%;
		outline:0 !important;

		font-size:10px;
		font-family:"sero",sans-serif;
		font-weight:400;
		color:#161a1e;
		background:#f5f5f5;

		-moz-osx-font-smoothing:grayscale;
		-webkit-font-smoothing:antialiased;
		text-size-adjust:none;
	}

	body {
		min-height:100%;
		overflow-y:scroll;
		padding:0;
		margin:0;
	}

	* {
		-webkit-touch-callout:none; /* prevent callout to copy image, etc when tap to hold */
		-webkit-text-size-adjust:none; /* prevent webkit from resizing text to fit */
		-webkit-tap-highlight-color:rgba(0,0,0,0); /* prevent tap highlight color / shadow */
		-webkit-user-select:text; /* prevent copy paste, to allow, change 'none' to 'text' */
	}

	img {
		display:block;
		max-width:100%;
		height:auto;
	}

	a:focus,
	input:focus,
	button:focus {
		outline:0;
	}

	textarea {
		resize:none;
	}

	#___gatsby {
		height:100%;
	}

	/* #root is from storybook */
	#app,
	#root,
	#gatsby-focus-wrapper {
		min-height:100%;
		display:flex;
		flex-direction:column;
	}
`;

/*
 *
 */
function Meta ({ children, lang }) {

	const intl = useIntl();

	return (
		<React.Fragment>
			<Helmet titleTemplate={`%s | ${intl.formatMessage({ id: 'title' })}`}>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="theme-color" content="#000000" />
				<meta property="og:image" content={`http://www.aide-aux-restaurateurs.fr/img/share_fr.jpg`} />
				<meta property="og:url" content="https://www.aide-aux-restaurateurs.fr" />
				<meta property="og:locale" content="fr" />
			</Helmet>
			{children}
		</React.Fragment>
	);
}

/*
 *
 */
function Layout ({ children, location }) {

	const theme = {
		column: '120rem',
		spacing: '4rem',
		spacing_mobile: '1.5rem',
		media: {
			phone_large: `min-width: ${700}px`,
			tablet: `min-width: ${1024}px`,
		}
	};

	const url = location.pathname;
	const langs = Object.keys(translations);
	const lang = getCurrentLangKey(langs, DEFAULT_LANG, url);
	const messages = translations[lang];

	return (
		<React.Fragment>
			<IntlProvider locale={lang} key={lang} messages={messages}>
				<ThemeProvider theme={theme}>
					<GlobalStyle key="ok" />
					<PageContent>
						<AppBar location={location} />
						<Meta lang={lang}>
							{children}
						</Meta>
					</PageContent>
					<PageFooter>
						<Footer />
					</PageFooter>
				</ThemeProvider>
			</IntlProvider>
		</React.Fragment>
	);
}

export default Layout;
