/*
 *
 */
import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Link } from 'gatsby';
import { getCurrentLangKey } from 'ptz-i18n';
import i18n, { DEFAULT_LANG } from 'i18n';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { slide as Menu } from 'react-burger-menu'

const HEIGHT_MOBILE = 6.5;
const HEIGHT_DESKTOP = 9;

const Bar = styled.header`
	position:fixed;
	width:100%;
	top:0;
	background:#fff;
	border-bottom: 1px solid #eaeaea;
`;

const Buffer = styled.div`
	height: ${HEIGHT_MOBILE}rem;

	@media (${props => props.theme.media.tablet}) {
		height: ${HEIGHT_DESKTOP}rem;
	} 
`;
const Content = styled.div`
	margin:0 auto;
	height: ${HEIGHT_MOBILE}rem;

	padding:0 2.5rem;
	max-width:${({ theme }) => theme.column};
	box-sizing:border-box;

	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-wrap:wrap;

	@media (${props => props.theme.media.tablet}) {
		flex-wrap:nowrap;
		padding:0 ${({ theme }) => theme.spacing};
		height: ${HEIGHT_DESKTOP}rem;
	}
`;

const Title = styled.p`
	margin:0;

	color:inherit;
	font-size:1.8rem;
	font-weight:500;
	text-decoration:none;

	width:100%;

	@media (${props => props.theme.media.tablet}) {
		margin:0;
		width:auto;
		text-align:left;
		font-size:1.6rem;
	}
`;

const Nav = styled.ul`
	margin:0;
	padding:0;
	display: none;
	@media (${props => props.theme.media.tablet}) {
		margin-left:auto;
		display:inline-block;
	}
`;

 const NavItem = styled.a`
 	margin-right:3rem;
	display:inline-block;
	margin-left:auto;
	font-size:1.4rem;
	color:#161a1e;
	text-decoration: none;
	font-weight: 500;
	transition: 0.2s;
	border-bottom: 1px solid transparent;
 	
	&.active {
		color: #1b83a4;
	} 
	
	&:hover {
	    color: #1b83a4;
	    border-bottom: 1px solid #1b83a4;
    }
`;

 const MainCTA = styled.a`
	display: inline-block;
    font-size: 1.4rem;
    color: #1b83a4;
    background: #fff;
    border: 1PX solid;
    padding: 1.35rem 2.2rem;
    border-radius: .35rem;
    text-decoration: none;
    transition: 0.3s;
    font-weight: 500;

	&:hover {
		color: #fff;
		background: #1b83a4;
		border-color: #1b83a4;
	}
`;

const BurgerMenu = styled.div`
	@media (${props => props.theme.media.tablet}) {
		display:none;
	}	
`

var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '2rem',
    height: '1.5rem',
    left: 'initial',
    right: '2.5rem',
    top: '2.5rem',
    zIndex: 3,
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#1b83a4'
  },
  bmCrossButton: {
    height: '1.5rem',
    width: '1.5rem',
    right: '1.5rem',
    top: '1.5rem'
  },
  bmCross: {
    background: '#bdc3c7',
 },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: '#fff',
    padding: '3.25em 0 0',
    fontSize: '1.7em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    display: 'flex',
    flexDirection: 'column',
  },
  bmItem: {
    display: 'inline-block',
	marginLeft: '1rem',
    padding: '3rem 4rem',
    margin: '0rem',
    borderTop: '1px solid #dfdfdf',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.6)'
  }
}


/*
 *
 */

export function AppBar ({ location }) {

	const intl = useIntl();
	const url = location.pathname;
	const langs = Object.keys(i18n);
	const langKey = getCurrentLangKey(langs, DEFAULT_LANG, url);
	const homeLink = `/${langKey !== DEFAULT_LANG ? langKey : ''}`;

	return (
		<React.Fragment>
			<BurgerMenu>
				<Menu right styles={ styles }>
			        <NavItem id="home" className="menu-item" as={Link} to={`${langKey !== DEFAULT_LANG ? `/${langKey}` : ''}/`} activeClassName="active">{intl.formatMessage({ id: 'nav.home' })}</NavItem>
			        <NavItem id="list" className="menu-item" as={Link} to={`${langKey !== DEFAULT_LANG ? `/${langKey}` : ''}/list`} activeClassName="active">{intl.formatMessage({ id: 'nav.list' })}</NavItem>
			        <NavItem id="carte" className="menu-item" as={Link} to={`${langKey !== DEFAULT_LANG ? `/${langKey}` : ''}/carte`} activeClassName="active">{intl.formatMessage({ id: 'nav.carte' })}</NavItem>
			        <NavItem href="https://blog.aide-aux-restaurateurs.fr">{intl.formatMessage({ id: 'nav.blog' })}</NavItem>
			        {/*<NavItem id="outils" className="menu-item" as={Link} to={`${langKey !== DEFAULT_LANG ? `/${langKey}` : ''}/outils`} activeClassName="active">{intl.formatMessage({ id: 'nav.outils' })}</NavItem>*/}
			        <NavItem id="about-us" className="menu-item" as={Link} to={`${langKey !== DEFAULT_LANG ? `/${langKey}` : ''}/about-us`} activeClassName="active">{intl.formatMessage({ id: 'nav.about_us' })}</NavItem>
			        <NavItem 
			        	id="add_restaurant" 
			        	className="menu-item" target="_blank" 		
						as={Link} 
						to={`${langKey !== DEFAULT_LANG ? `/${langKey}` : ''}/ajouter-un-restaurant`}
						activeClassName="active"
						onClick={e => {		
					        // Lets track that custom click		
					        trackCustomEvent({		
					          // string - required - The object that was interacted with (e.g.video)		
					          category: "Button",		
					          // string - required - Type of interaction (e.g. 'play')		
					          action: "Pack Relance",		
					          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')		
					          label: "Nav"		
					        })		
					      }}>
						{intl.formatMessage({ id: 'nav.add_restaurant' })}</NavItem>		        
			     </Menu>
			</BurgerMenu>
			<Bar>
				<Content>
					<Title as={Link} to={homeLink}>
						{intl.formatMessage({ id: 'title' })}
					</Title>

					<Nav>
						<NavItem as={Link} to={`${langKey !== DEFAULT_LANG ? `/${langKey}` : ''}/list`} activeClassName="active">
							{intl.formatMessage({ id: 'nav.list' })}
						</NavItem>
						<NavItem id="carte" className="menu-item" as={Link} to={`${langKey !== DEFAULT_LANG ? `/${langKey}` : ''}/carte`} activeClassName="active">{intl.formatMessage({ id: 'nav.carte' })}</NavItem>
						<NavItem href="https://blog.aide-aux-restaurateurs.fr">
							{intl.formatMessage({ id: 'nav.blog' })}
						</NavItem>
						{/*<NavItem as={Link} to={`${langKey !== DEFAULT_LANG ? `/${langKey}` : ''}/outils`} activeClassName="active">
							{intl.formatMessage({ id: 'nav.outils' })}
						</NavItem>*/}
						<NavItem as={Link} to={`${langKey !== DEFAULT_LANG ? `/${langKey}` : ''}/about-us`} activeClassName="active">
							{intl.formatMessage({ id: 'nav.about_us' })}
						</NavItem>
						<MainCTA 
							as={Link} 
							to={`${langKey !== DEFAULT_LANG ? `/${langKey}` : ''}/ajouter-un-restaurant`}
							onClick={e => {		
						        // Lets track that custom click		
						        trackCustomEvent({		
						          // string - required - The object that was interacted with (e.g.video)		
						          category: "Button",		
						          // string - required - Type of interaction (e.g. 'play')		
						          action: "Pack Relance",		
						          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')		
						          label: "Nav"		
						        })		
						      }}>
							{intl.formatMessage({ id: 'nav.add_restaurant' })}
						</MainCTA>
					</Nav>	
				</Content>
			</Bar>
			<Buffer />
		</React.Fragment>
	);
}

export default AppBar;