/* eslint-disable jsx-a11y/accessible-emoji */

/*
 *
 */
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Foot = styled.div`
	border-top:1px solid #eaeaea;
	background:#f9f9f9;
`;

const Content = styled.div`
	margin:0 auto;
	padding:4rem ${({ theme }) => theme.spacing};
	max-width:${({ theme }) => theme.column};


	font-size:1.4rem;
	text-align:center;
`;

const MentionLegales = styled.div`
	margin-top:2rem;
	font-size:1.4rem;
	text-align:center;
`;

/*
 *
 */
export function Footer ({ children }) {

	return (
		<Foot>
			<Content>
				<div>
				<FormattedMessage
					id="footer.credits"
					values={{
						love: (...chunks) => (
							<span as="span" role="img" aria-label="love">
								{chunks}
							</span>
						)
					}}
				/>
				</div>
				<MentionLegales>
				<a 
					rel="nofollow noindex noreferrer noopener" 
					href="./mentions-legales"
					>
					<span>Mentions Legales</span>
				</a>
				<span> | </span>
				<a 
					rel="nofollow noindex noreferrer noopener" 
					target="_blank" 
					href="https://www.centralapp.com/fr/vie-privee"
					>
					<span>Politique de Confidentialité & Cookies</span>
				</a>
				</MentionLegales>
			</Content>
		</Foot>
	);
}

export default Footer;
