/*
 *
 */
import React from 'react';
import styled, { css } from 'styled-components';

/*
 * Styles
 */
const Root = styled.div`

	${({ type }) => {
		switch (type) {
			case 'color':
				return css`
					background:#F6C277;
				`;
			case 'normal':
			default:
				return css`
					&:nth-child(even) {
						background:#f9f9f9;
					}

					&:nth-child(odd) {
						background:#f5f5f5;
					}
				`;
		}
	}}

	border-bottom:1px solid #eaeaea;

	&:last-child {
		border-bottom:0;
	}
`;

const Content = styled.div`
	margin:0 auto;
	box-sizing:border-box;

	${({ spacing }) => {
		switch (spacing) {
			case 'no-padding':
				return css`
					padding:0rem;
				`;
			case 'normal':
			default:
				return css`
					padding:3rem ${({ theme }) => theme.spacing_mobile};
					max-width:${({ theme }) => theme.column};

					@media (${props => props.theme.media.tablet}) {
						padding:6rem ${({ theme }) => theme.spacing};
					}
				`;
		}
	}}

`;

const Title = styled.h2`
	margin:0 0 1.5em;
	font-size:2.2rem;
	font-weight:600;
`;

/*
 *
 */
export function Section (props) {
	return (
		<Root type={props.type}>
			<Content spacing={props.spacing}>
				{props.title && (
					<Title>{props.title}</Title>
				)}
				{props.children}
			</Content>
		</Root>
	);
}

export default Section;
